.home-icon {
    width: 6rem;
    margin-bottom: 5rem;
}

._SelectHotelsOrHospital {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f7f7f7;
}

.absolute_img {
    position: absolute;
    left: 2%;
    top: 10px;
}

.absolute_img img {
    width: 300px;
    height: auto;
}

.bottom_img {
    max-width: 465px;
    height: auto;
    margin-left: 12px;
}

._SelectHotelsOrHospital .card-text {
    font-size: 1rem;
    text-transform: uppercase;
}

._SelectHotelsOrHospital .col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.cardsContainer {
    min-height: 30rem;
}

.cardHolder {
    width: 14rem;
}

.card {
    max-width: 25.5rem;
    border: none;
    align-items: center;
    box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.25);
    min-width: 11rem;
}

.card-left {
    border-radius: 0rem 0 0 1rem;
}

.card-right {
    border-radius: 0 0 1rem 0;
}


._SelectHotelsOrHospital .card-footer {
    background: none;
    border: none;
}


.selectionBtn {
    width: 4.8rem;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -1.8rem;
    transition: width .2s;
}

.selectionBtn:hover {
    width: 4.25rem;
}

._SelectHotelsOrHospital .card-body {
    margin-top: 2rem;
}

._SelectHotelsOrHospital h2 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 40px;
    color: #2F2E41;
}

._SelectHotelsOrHospital h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: rgba(63, 61, 86, 0.55);
}

._SelectHotelsOrHospital h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #555555;
}


.GostDiv {
    height: 100vh;
    position: absolute;
    top: 36rem;
}


.HospitalContainer {
    background-repeat: no-repeat;
    background-size: auto 27rem;
    background-position: right top;
    background-image: url("./../../../assets/Images/LandingPage/bg-hospital.jpg");
    background-color: #432a01;
}

.HotelContainer {
    background-repeat: no-repeat;
    background-size: auto 27rem;
    background-position: left top;
    background-image: url("./../../../assets/Images/LandingPage/bg-med.jpg");
    background-color: #03bef1;
}

@media screen and (max-width: 1060px) {
    .absolute_img {
        position: relative;
        /* left: 10%; */
    }
}

@media screen and (max-width: 768px) {
    .card-left {
        border-radius: 1rem;
        margin-top: 1rem;
    }

    .card-right {
        border-radius: 1rem;
        margin-top: 1rem;
    }

    .absolute_img img {
        width: 200px;
    }

    .bottom_img {
        width: 100%;
    }
}