.roomHover img {
    min-height: 250px;
}

#bookBtn:disabled,
#bookBtn[disabled] {
    background-color: #9b9b9b;
    opacity: 1;
    color: white;
    border: none;
}

.BookingContainer {
    padding-top: 2rem;
    /* margin-bottom: 18rem; */
    z-index: 100;
    position: relative;
}

.rooms h3 {
    font-weight: 600;
    font-size: 1.5rem;
    color: #A5A6F6;
}

.rooms h4 {

    font-weight: 400;
    font-size: 1.5rem;
    color: #A5A6F6;
}

.rooms p {

    font-weight: 400;
    font-size: 1.6rem;
    color: white;
}

.custom-card-img-top {
    border-radius: 500000px;
}

.rooms .card p {
    color: gray;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #555555;
}

.rooms .card h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #000000;
}

.rooms .card {
    background: #78D2CF;
    border-radius: 20px;
}

.rooms .card-body {
    background: #ffff;
    border-radius: 20px;
}

.roomHover:hover {
    border-radius: 30px;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}


.rooms .card-body {
    background: #ffff;
    border-radius: 0 0 20px 20px;
}



.roomHover img {
    height: 300px;
}

.label {
    font-size: .8rem;
    color: #FFCB08;
}









.hotel_booking_table {
    /* background-color: rgb(219, 184, 255); */
    background-color: #3D1A6E;

    align-items: center;
    border-radius: 20px;
    color: #A5A6F6;

    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;

}

.bookingSubmitButton {
    position: relative;
    top: -2.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #BC2A75 !important;
    color: #fff !important;
}
.bookingSubmitButton:hover {
    background-color: #800243 !important;
    color: #fff !important;
}

.bookRoom {
    position: relative;
    top: 2.5rem;
    background-color: #BC2A75;
    border-radius: 15px;
    color: white;
    display: flex;
    align-items: center;
}



.bookRoom h6 {
    padding: 0;
    margin: 0;
}

.hotel_booking_table .btn {
    background-color: #FE538A;
}

.hotel_booking_table select {


    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;


    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;


}









.st0 {
    fill: #9B51E0;
}

.rooms .st1 {
    fill: #BB6BD9;
}

.rooms .st2 {
    fill: #7879F1;
}

.rooms .st3 {
    fill: #56CCF2;
}

.rooms .st4 {
    fill: #2D9CDB;
}

.rooms .st5 {
    fill: #A5A6F6;
}

.rooms .st6 {
    fill: #FFFFFF;
}