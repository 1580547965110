/* .tableContainer {
    border-style: solid;
    border-radius: 25px;
    border-color: #686868;
    padding: 1rem;
} */

.sliderBG {
    height: 100vh;
    position: relative;
    background-color: #fff;
    /* background-image: url('./../../../assets/Images/MapBackground.png'); */
}

.sliderBG .swapDown {
    border: none;
    border-radius: 1rem;
    font-size: 1.6rem;
    width: 3rem;
    position: absolute;
    bottom: -2.5rem;
    z-index: 10;
    padding: .5rem;
    background-color: #BC2A75;
    color: white;
    transition: 0.1s;
    left: 0;
    right: 0;
    margin: auto;
}

.sliderBG .swapDown:hover {
    background-color: black;
}

.label-container {
    display: flex;
    margin: auto;
    width: 70vw;
    max-width: 65rem;
    height: 1.5rem;
    font-size: 1.2rem;
    font-weight: bold;
}

.Serial-label {
    float: left;
    width: 5.5rem;
    text-align: center;
}

.name-label {
    float: right;
    text-align: center;
    width: 100%;
}

.selectionContainer {
    height: 100vh;
    width: 100%;
    margin: auto;
    padding-top: 5rem;
    background-color: #fff;
    /* background-image: url('./../../../assets/Images/MapBackground.png'); */
    background-size: cover;
    background-position: center center;
}

.selectionContainer h1 {
    color: #3d1a6f;
    text-align: center;
    font-weight: bold;
}

.Hotel-Selection-Container {
    overflow-x: hidden;
    overflow-y: scroll;
    margin: auto;
    max-width: 64rem;
    height: 80vh;
    padding: 0 5rem 0 5rem;
}

/* .positioning-helper {
    content: ' ';
    height: 4.5rem;
    max-width: 65rem;
    float: left;
    margin: auto;
} */

.Hotel-Selection-Btn {
    outline: none;
    margin: auto;
    display: flex;
    width: 70vw;
    min-height: 2.8rem;
    background: #AE20A9;
    opacity: 0.6;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.35);
    border-radius: 7px;
    font-size: .9rem;
    font-weight: 600;
    color: #ffffff;
    transition: .1s;
    margin-top: 0.5rem;
    max-width: 33rem;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-color: #000000c2;
    border-width: 1px;
}

.Hotel-Selection-Btn:hover,
.Hotel-Selection-Btn:focus {
    background-color: #00CD77 !important;
    color: white;
    font-size: .95rem;
    cursor: pointer;
}


.Hotel-Selection-icon,
.Hotel-Selection-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    width: 1.5rem;
    padding: 0rem 1rem 0rem 1.5rem;
    margin-left: 0.5rem;
}

.Hotel-Selection-icon img {
    width: 2rem;
}

.Hotel-Selection-Name {
    overflow-wrap: break-word;
    float: right;
    width: 100%;
    color: #fafafa;
}

.Hotel-Selection-arrow {
    float: right !important;
    width: 2.5rem !important;
    font-size: 1.3rem;
}

/*not found div*/
.not-found-cont {
    height: 35vh;
    width: 100%;
}

.not-found-cont h1 {
    font-weight: bold;
    text-align: center;
    color: #3d1a6f;
    margin-top: 5rem;
}