.form_page {
    /* background: red; */
    padding: 2rem;
    text-align: center;
}

.form_option {
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.form_option p {
    background-color: #f1f1f1;
    padding: 15px 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
}

.center_txt {
    text-align: center;
    padding: .8rem;
}