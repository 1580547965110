#menu_nav {
  width: 38rem;
  bottom: -22rem;
}

@media screen and (max-width: 2000px) {
  #menu_nav {
    width: 38rem;
    bottom: -22rem;
  }
}

@media screen and (max-width: 1800px) {
  #menu_nav {
    width: 32rem;
    bottom: -18rem;
  }
}

@media screen and (max-width: 1500px) {
  #menu_nav {
    width: 32rem;
    bottom: -18rem;
  }
}


@media screen and (max-width: 1366px) {
  #menu_nav {
    width: 28rem;
    bottom: -16rem;
  }
}

@media screen and (max-width: 1200px) {
  #menu_nav {
    width: 28rem;
    bottom: -16rem;
  }
}

@media screen and (max-width: 991px) {
  #menu_nav {
    width: 28rem;
    bottom: -16rem;
  }
}


@media screen and (max-width: 876px) {
  #menu_nav {
    width: 25rem;
    bottom: -14rem;
  }
}

@media screen and (max-width: 768px) {
  #menu_nav {
    width: 25rem;
    bottom: -14rem;
  }
}

@media screen and (max-width: 640px) {
  #menu_nav {
    width: 25rem;
    bottom: -14rem;
  }
}

@media screen and (max-width: 560px) {
  #menu_nav {
    width: 23rem;
    bottom: -13rem;
  }
}

@media screen and (max-width: 480px) {
  #menu_nav {
    width: 18rem;
    bottom: -10rem;
  }
}