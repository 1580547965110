.departmentText {
    color: white;
}
.spinner-cont{
    height: 78vh;
}
.hotelDiv{
    height: unset !important;
}

