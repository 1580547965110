.OurHotels {
	background: #ffffff;
	color: #3d1a6f;
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.OurHotels svg {
	color: white;
}


.OurHotels h3 {
	font-weight: 700;
	font-size: 3rem;
}

.OurHotels h4 {
	font-weight: 400;
	font-size: 1.875rem;
}

.bottom-map-img {
	background-position: center top;
	background-size: cover;
	background-color: #fff;
	background-image: url('./../../../assets/Images/MapBackground.png');
	height: 5.3rem;
	width: 100%;
	bottom: 0;
	position: absolute;
}

.map_container {
	height: 100vh;
}

.hotel_txt {
	position: absolute;
	bottom: 0;
	width: 10rem;
	z-index: 10;
	margin: auto;
	margin-bottom: auto;
	left: 0;
	right: 0;
	text-align: center;
	margin-bottom: 3.8rem;
	font-weight: bold;
}

@media screen and (max-width: 484px) {
	.hotel_txt {
		margin-bottom: 1rem;
	}
}

.h {
	background-color: #3D1A6E;
	background-color: #FFCB08;
	background-color: #E51D75;
}