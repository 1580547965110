.doctors {
    background: transparent;
}

.cardsCont {
    margin: auto;
    display: flex;
    width: auto;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 2rem;
    z-index: 100;
    padding-top: 2rem;
    margin-bottom: 18rem;
    position: relative;
}

.cardsCont::-webkit-scrollbar-track {
    border: none;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.192);
}

.cardsCont::-webkit-scrollbar {
    height: 0.5rem;
}

.cardsCont::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #1b1b1ba2;
    border: none;
    height: 0.5rem;
}

.doctors h3 {
    font-weight: 700;
    font-size: 3rem;
    color: white;
}

.doctors h4 {

    font-weight: 400;
    font-size: 2.5rem;
    color: white;
}

.doctors p {

    font-weight: 400;
    font-size: 1.6rem;
    color: white;
}

.custom-card-img-top {
    border-radius: 500000px;
}

.doctors .card p {
    color: gray;
    font-weight: 400;
    font-size: .8rem;
    line-height: 15px;
    color: #555555;
}

.doctors .card h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #000000;
}

.doctors .card {
    background: transparent;
    border-radius: 25px;
    overflow: hidden;
}

.doctors .card-body {
    background: #ffff;
    border-radius: 0 0 20px 20px;
    width: 100%;
}

.doctorHover:hover {
    border-radius: 30px;
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    transition: all .1s;
}

.doctorHover img {
    width: auto !important;
    height: 13rem;
    /* border-radius: 1rem 1rem 0 0 !important; */
    max-width: unset !important;
}

.card-cont {
    width: 12rem;
    margin: .5rem;
}