.navBlue {
  background-color: #3d1a6f !important;
  color: goldenrod !important;
}

.navBlue a {
  text-decoration: none;
  color: #f6d001 !important;
  padding: 0.5rem;
}
/* 
.headerLogo{
    width: 10.4rem;
} */

.headerLogo {
  width: 5.4rem;
  height: auto;
}

@media screen and (max-width: 700px) {
  /* .headerLogo{
        width: 8rem;
    } */
}
