.departments {
    position: relative;
    background: #fff;
    justify-content: center;
    overflow: hidden;
    height: unset;
}

.departments-hospital {
    position: relative;
    background: #fff;
    justify-content: center;
    overflow: hidden;
    height: 110vh;
}

.container h1 {
    margin-top: 10rem;
    color: #3D1A6E;
    font-weight: 800;
    font-size: 3rem;
}

.doctors {
    overflow: hidden;
    /* height: 100vh; */
    position: relative;
}

.departments h3 {
    font-weight: 700;
    font-size: 3rem;

}

.departments h4 {

    font-weight: 400;
    font-size: 2.5rem;
}

.departments p {

    font-weight: 400;
    font-size: 1.6rem;
}


.halfSpinnerAnimation {
    animation: .5s linear infinite rotate;
    position: relative;
    transform-origin: 50% 100%;
}

.header-hospital-txt {
    text-transform: uppercase !important;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .header-hospital-txt {
        text-transform: uppercase !important;
        text-align: center;
        font-size: 2rem !important;
    }
}