.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo {
  font-size: 40px;
  font-weight: 900;
  padding: 15px 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#fixedbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 5rem;
  right: 2.5rem;
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  outline: 0;
  border: 0;
  color: white;
  background-color: #BC2A75;
  z-index: 9999;
}

.btn-shadow {
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}
